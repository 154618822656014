









import Vue from "vue";

export default Vue.extend({
  name: "DarkModeSwitcher",

  components: {},

  data() {
    return {
      isDarkTheme: true,
    };
  },
  methods: {
    onClickDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.isDarkTheme = this.$vuetify.theme.dark;
    },
  },
  computed: {
    iconSource(): string {
      return this.isDarkTheme ? "mdi-weather-night" : "mdi-weather-sunny";
    },
    iconTheme(): string {
      return this.$vuetify.theme.dark ? "blue" : "";
    },
  },
  watch: {
    isDarkTheme() {
      this.$vuetify.theme.dark = this.isDarkTheme;
    },
  },
});
