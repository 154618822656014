











import Vue from "vue";
import { watchPointsDataEndpoints } from "../../GlobalConst/GlobalConst.vue";
export default Vue.extend({
  props: { propsTime: String, propsKeo: Number },
  data() {
    return {
      darkKeogramBaseURL: "",
      keogramBaseURL: "",
      keogramURL: "",
    };
  },
  methods: {
    reloadkeogramURL: function () {
      if (this.isDarkTheme) {
        // explicitly dark
        this.keogramURL =
          watchPointsDataEndpoints[
            localStorage.getItem("observationPoint") ?? "Tromsø"
          ].darkKeogramBaseURL + this.propsTime;
        +this.propsTime;
      } else {
        // explicitly light
        this.keogramURL =
          watchPointsDataEndpoints[
            localStorage.getItem("observationPoint") ?? "Tromsø"
          ].keogramBaseURL + this.propsTime;
      }
    },
  },
  watch: {
    propsTime: function () {
      this.reloadkeogramURL();
    },
    isDarkTheme() {
      this.reloadkeogramURL();
    },
  },
  computed: {
    isDarkTheme(): boolean {
      return this.$vuetify.theme.dark ? true : false;
    },
  },
});
