
















































































import Vue from "vue";

import LicenseTable from "./LicenseTable.vue";

export default Vue.extend({
  data() {
    return {
      rulesOfTheRoad: false,
      license: false,
      value: true,
    };
  },

  components: {
    LicenseTable,
  },

  computed: {
    bottomNavTheme(): string {
      return this.$vuetify.theme.dark ? "#1E1E1E" : "white";
    },
  },
});
