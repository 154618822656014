































import Vue from "vue";
import DarkModeSwitcher from "./DarkModeSwitch.vue";
import SoundSwitch from "./SoundSwitch.vue";
import ObservationPoint from "./SlelectObservationPoint.vue";

import { watchPointsDataEndpoints } from "../../GlobalConst/GlobalConst.vue";

export default Vue.extend({
  name: "HomeHeader",

  components: { DarkModeSwitcher, SoundSwitch, ObservationPoint },
  data() {
    return {
      sum: 0, // 変更検知呼び出し用変数
      awesome: true,
      BurstObservationListUri: "/BurstObservationList",
      BurstObservationToName: "Burst Observation",
    };
  },
  methods: {
    getDataJson() {
      this.$emit("getDataJson");
      this.sum++;
    },
    getRouteName(): string {
      const rtn = this.$route.name === "Home" ? "Archive" : "Home";
      return rtn;
    },
    setRoutePass(): string {
      const rtn = this.$route.name === "Home" ? "/Archive" : "Home";
      return rtn;
    },
  },
  computed: {
    title: function () {
      this.sum.toString();
      return watchPointsDataEndpoints[
        localStorage.getItem("observationPoint") ?? "Tromsø"
      ].Title;
    },
  },
});
