












import Vue from "vue";
import { watchPointsDataEndpoints } from "../../GlobalConst/GlobalConst.vue";

export default Vue.extend({
  props: { propsTime: String },
  data() {
    return {
      latestViewBaseURL: "",
      latestViewURL: "",
      cookieObservationPoint: "",
    };
  },
  watch: {
    propsTime: function () {
      this.cookieObservationPoint =
        localStorage.getItem("observationPoint") ?? "Tromsø";

      this.latestViewURL =
        watchPointsDataEndpoints[this.cookieObservationPoint]
          .latestViewBaseURL + this.propsTime;
    },
  },
});
