import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import TromsoeAI from "../views/TromsoeAI.vue";
import Archive from "../views/Archive.vue";
import AurstObservationList from "../views/BurstObservationList.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    alias: "/Home",
    component: TromsoeAI,
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive,
  },
  {
    path: "/BurstObservationList",
    name: "BurstObservationList",
    component: AurstObservationList,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
