





























import Vue, { PropType } from "vue";
import { JifsData } from "../Models/JifsData";
import BurstCarousel from "../Carousel/BurstCarousel.vue";
import BurstKeogram from "@/components/Carousel/BurstKeogram.vue";

export default Vue.extend({
  name: "BurstDialog",
  components: { BurstCarousel, BurstKeogram },
  props: {
    propsBurstGifUrls: { type: Array as PropType<string[]> },
    propsKeogramUrl: { type: String },
  },
  data() {
    return {
      JifsData: new Array<JifsData>(),
      dialog: false,
    };
  },
});
