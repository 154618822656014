





















import Vue from "vue";
import { PropType } from "vue/types/umd";
export default Vue.extend({
  name: "BurstCarousel",
  props: {
    propsBurstGifUrls: { type: Array as PropType<string[]> },
  },
  data: () => ({
    carouselModel: 0,
  }),
  computed: {
    fileName: function (): string {
      var listUri = this.propsBurstGifUrls[this.carouselModel].split("/");
      return listUri[listUri.length - 1];
    },
  },
});
