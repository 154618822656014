

































































import Vue, { PropType } from "vue";
import Push from "push.js";
import Sound from "../../assets/sound.mp3";

interface PropsAurora {
  Arc?: number;
  Discrete?: number;
  Diffuse?: number;
  "Aurora but cloudy"?: number;
  "Aurora but bright"?: number;
  Clear?: number;
  Cloudy?: number;
  "Dusk/Dawn"?: number;
}

export default Vue.extend({
  props: {
    propsAurora: Object as PropType<PropsAurora>,
    propsUpdateTime: String,
  },

  data() {
    return {
      selectedItemList: ["Arc", "Discrete", "Diffuse"],
      // audio: new Audio(require("@/assets/")),
    };
  },
  computed: {
    iconTheme(): string {
      return this.$vuetify.theme.dark ? "blue" : "";
    },
  },
  watch: {
    propsAurora: function () {
      // 前回の取得データと更新時刻を比較して、更新されていたら通知を送る
      if (this.isUpdated()) {
        this.buildNotification();
      }
    },
    selectedItemList: function () {
      const parsedSelectedAuroraList = JSON.stringify(this.selectedItemList);
      this.removeAuroraList(parsedSelectedAuroraList);
    },
  },
  mounted() {
    const localStrageAurora = localStorage.getItem("selectedAuroraList");
    if (localStrageAurora) {
      this.selectedItemList = JSON.parse(localStrageAurora);
    } else {
      const parsedSelectedAuroraList = JSON.stringify(this.selectedItemList);
      localStorage.setItem("selectedAuroraList", parsedSelectedAuroraList);
    }
  },
  methods: {
    removeAuroraList: function (parsedSelectedAuroraList: string) {
      localStorage.removeItem("selectedAuroraList");
      localStorage.setItem("selectedAuroraList", parsedSelectedAuroraList);
    },
    testNotification: function () {
      Push.create("Tromsø AI", {
        body: "Success!",
        icon: "/favicon.ico",
        timeout: 4000,
        onClick: function () {
          window.focus();
        },
      });

      // Audioの作成 TODO 毎回作成するのは重そう
      const audio = new Audio(Sound);
      // ミュート設定されていなければ通知音を出す
      if (!Vue.prototype.$isMute) {
        audio.play();
      }
    },
    buildNotification: function () {
      // チェックボックスに入っているオーロラの合計値が80%以上のとき、
      // 通知を送る
      // 表示するのは最大値の名称
      let statusSum = 0;
      let maxAuroraClass = "Ark";
      let maxProbability = 0;
      for (var item of this.selectedItemList) {
        statusSum += this.propsAurora[item];
        if (this.propsAurora[item] > maxProbability) {
          maxProbability = this.propsAurora[item];
          maxAuroraClass = item;
        }
      }
      if (statusSum >= 80) {
        Push.create("Tromsø AI", {
          body: maxAuroraClass + " has appeared!",
          icon: "/~nanjo/public/aurora_alert/latest.jpg",
          timeout: 4000,
          onClick: function () {
            window.focus();
          },
        });
        // Audioの作成 TODO 毎回作成するのは重そう
        const audio = new Audio(Sound);
        // ミュート設定されていなければ通知音を出す
        if (!Vue.prototype.$isMute) {
          audio.play();
        }
      }
    },
    isUpdated: function () {
      const lastUpdateTime = localStorage.getItem("lastUpdateTime");

      // ローカルストレージ内の最終更新時間がnullか最新ではなかったらtrueを返す
      if (lastUpdateTime === null || lastUpdateTime != this.propsUpdateTime) {
        localStorage.setItem("lastUpdateTime", this.propsUpdateTime);
        return true;
      } else {
        // ローカルストレージ内の最終更新時刻が前回と同値だったらfalseを返す
        return false;
      }
    },
  },
});
