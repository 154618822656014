








import Vue from "vue";

export default Vue.extend({
  name: "SoundSwitch",

  components: {},

  data() {
    return {
      isMute: true,
    };
  },
  mounted() {
    Vue.prototype.$isMute = this.isMute;
  },
  computed: {
    iconSource(): string {
      return this.isMute ? "mdi-volume-off" : "mdi-volume-high";
    },
    iconTheme(): string {
      return this.$vuetify.theme.dark ? "blue" : "";
    },
  },
  methods: {
    onClickSound() {
      this.isMute = !this.isMute;
      // グローバル変数を汚さないために、prototypeを利用する
      Vue.prototype.$isMute = this.isMute;

      // ミュート設定をローカルストレージに保存する
      localStorage.setItem("isMute", Vue.prototype.$isMute.toString());
    },
  },
});
