











import Vue from "vue";
import { watchPoints } from "../../GlobalConst/GlobalConst.vue";

export default Vue.extend({
  name: "SlelectObservationPoint",
  components: {},
  data() {
    return {
      items: watchPoints,
      Observatory: localStorage.getItem("observationPoint"),
    };
  },
  methods: {},
  computed: {},
  watch: {
    Observatory() {
      localStorage.setItem("observationPoint", this.Observatory ?? "");
      this.$emit("getDataJson");
      // 多分不要 不具合出たら確認する
      // if (this.$route.name === "Archive")
      //   this.$router.go({
      //     path: this.$router.currentRoute.path,
      //     force: true,
      //   });
      if (this.$route.name === "Archive") {
        this.$router.go(0);
      }
    },
  },
});
