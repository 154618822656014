
























import Vue from "vue";
import DarkModeSwitcher from "./DarkModeSwitch.vue";
import SoundSwitch from "./SoundSwitch.vue";
import ObservationPoint from "./SlelectObservationPoint.vue";

import { watchPointsDataEndpoints } from "../../GlobalConst/GlobalConst.vue";

export default Vue.extend({
  name: "BurstHeader",

  components: { DarkModeSwitcher, SoundSwitch, ObservationPoint },
  data() {
    return {
      sum: 0, // 変更検知呼び出し用変数
    };
  },
  methods: {
    getDataJson() {
      this.$emit("getDataJson");
      this.sum++;
    },
    getRouteName(): string {
      const rtn = "Home";
      return rtn;
    },
    setRoutePass(): string {
      const rtn = "Home";
      return rtn;
    },
  },
  computed: {
    title: function () {
      this.sum.toString();
      return watchPointsDataEndpoints[
        localStorage.getItem("observationPoint") ?? "Tromsø"
      ].Title;
    },
  },
});
