
































import Vue from "vue";
// import axios from "axios";

import ArichiveCalendar from "../components/CardsFeatured/CardCalendar.vue";
import CardAerchiveKeo from "../components/CardsFeatured/CardArchiveKeo.vue";
import CardAerchiveMovie from "../components/CardsFeatured/CardArchiveMovie.vue";
import HomeHeader from "../components/HomeHeader/HomeHeader.vue";

export default Vue.extend({
  name: "Arichive",

  data() {
    return {
      archiveKeoURL: "",
      archiveMovieURL: "",
    };
  },
  components: {
    HomeHeader,
    ArichiveCalendar,
    CardAerchiveKeo,
    CardAerchiveMovie,
  },
  methods: {
    emitArchiveKeoURL(URL: string) {
      this.archiveKeoURL = URL;
    },
    emitArchiveMovieURL(URL: string) {
      this.archiveMovieURL = URL;
    },
    getDataJson(): string {
      return "";
    },
  },
});
