






















import Vue from "vue";
import BurstHeader from "../components/HomeHeader/BurstHeader.vue";
import HomeFooter from "../components/HomeFooter/HomeFooter.vue";
import CardBurstObsevationTable from "../components/CardsFeatured/CardBurstObsevationTable.vue";

import { JifsData } from "../components/Models/JifsData";

export default Vue.extend({
  components: { BurstHeader, HomeFooter, CardBurstObsevationTable },
  data() {
    return {
      gifsJson: Array<JifsData>(),
    };
  },
});
