






import Vue from "vue";
export default Vue.extend({
  props: {
    propsKeogramUrl: { type: String },
  },
  data: () => ({}),
});
