


























import Vue from "vue";
export default Vue.extend({
  props: { propsIsOpenDialog: Boolean },
  data() {
    return {
      isOpenDialog: this.propsIsOpenDialog,
    };
  },
});
