

















































































































































import Vue from "vue";
import axios from "axios";

import {
  monthNameList,
  monthList,
  monthDaysList,
  reverseMonthNameList,
  watchPointsDataEndpoints,
  archiveStartYear,
} from "../../GlobalConst/GlobalConst.vue";

export default Vue.extend({
  name: "CardCalendar",

  data() {
    const selectorYearList: number[] = [];
    const displayCalendarList: string[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let calendarData: any = {};
    const displayMonthList = monthList;

    const events: {
      name: string;
      start: Date;
      end: Date;
      color: string;
      timed: boolean;
    }[] = [];

    return {
      // セレクタ関連
      selectedYear: "",
      selectorYearList,
      displayMonthList,

      // カレンダー関連
      calendarList: [],
      displayCalendarList,
      type: "month",
      mode: "stack",
      value: "2012-11-1",

      // サーバから取得したデータ
      calendarData,

      // イベント系
      events,

      // 動画
      movieUrl: "",
      keogramUrl: "",
    };
  },
  mounted() {
    const dt = new Date();
    const thisYear = dt.getFullYear();
    for (
      let i =
        archiveStartYear[localStorage.getItem("observationPoint") ?? "Tromsø"];
      i <= thisYear;
      i++
    ) {
      this.selectorYearList.push(i);
    }
  },

  methods: {
    // カレンダーの表示リストを作成
    setMonthSelector: function () {
      // 表示する月日のリストを初期化する
      this.displayCalendarList = [];
      for (let key in this.calendarData[this.selectedYear]) {
        //   9~12月の場合
        //      年月をそのままセット
        //   1~4月の場合
        //      年 + 1 する
        switch (key) {
          case "September":
          case "October":
          case "November":
          case "December":
            this.displayCalendarList.push(
              this.selectedYear +
                "-" +
                this.getMMFromMonthName(key).toString() +
                "-" +
                "01"
            );
            break;
          case "January":
          case "February":
          case "March":
          case "April":
            this.displayCalendarList.push(
              this.selectedYear +
                1 +
                "-" +
                this.getMMFromMonthName(key).toString() +
                "-" +
                "01"
            );
            break;
        }
      }
    },
    // 月名からMM形式に変換する
    getMMFromMonthName: function (monthName: string) {
      for (let key in monthNameList) {
        if (key === monthName) {
          return monthNameList[key];
        }
      }
    },
    //日付クリックイベント
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClickDate(elem: any) {
      // 動画の表示
      this.movieUrl =
        this.calendarData[this.selectedYear][
          this.getMonthNameFromMM(elem.month)
        ][elem.day]["Movie"];
      this.$emit("archiveMovieURL", this.movieUrl);

      // Keogramの表示
      this.keogramUrl =
        this.calendarData[this.selectedYear][
          this.getMonthNameFromMM(elem.month)
        ][elem.day]["Keogram"];
      this.$emit("archiveKeoURL", this.keogramUrl);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClickEvent(elem: any) {
      // Keogramの表示
      this.keogramUrl =
        this.calendarData[this.selectedYear][
          this.getMonthNameFromMM(elem.eventParsed.start.month)
        ][elem.eventParsed.start.day]["Keogram"];
      this.$emit("archiveKeoURL", this.keogramUrl);

      // 動画の表示
      this.movieUrl =
        this.calendarData[this.selectedYear][
          this.getMonthNameFromMM(elem.eventParsed.start.month)
        ][elem.eventParsed.start.day]["Movie"];
      this.$emit("archiveMovieURL", this.movieUrl);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMonthNameFromMM(elem: any) {
      for (let key in reverseMonthNameList) {
        if (key == elem) {
          return reverseMonthNameList[key];
        }
      }
    },
    // イベント作成
    setEvent() {
      const events: {
        name: string;
        start: Date;
        end: Date;
        color: string;
        timed: boolean;
      }[] = [];
      const allDay = true;
      let eventYear = 0;
      for (let i = 0; i < this.displayMonthList.length; i++) {
        for (let j = 1; j <= monthDaysList[i]; j++) {
          if (i < 4) {
            eventYear = Number(this.selectedYear);
          } else {
            eventYear = Number(this.selectedYear) + 1;
          }

          const eventDate =
            eventYear +
            "-" +
            this.getMMFromMonthName(this.displayMonthList[i]) +
            "-" +
            this.zeroFill(j);
          const first = new Date(`${eventDate}T00:00:00`);

          events.push({
            name:
              this.calendarData[this.selectedYear][this.displayMonthList[i]][j]
                .Auroral +
              " / " +
              this.calendarData[this.selectedYear][this.displayMonthList[i]][j]
                .Total,
            start: first,
            end: first,
            color: this.getEventColor(
              this.calendarData[this.selectedYear][this.displayMonthList[i]][j]
                .Auroral
            ),
            timed: !allDay,
          });
          this.events = events;
        }
      }
      // うるう年の判定
      if ((Number(this.selectedYear) + 1) % 4 == 0) {
        const eventDate =
          eventYear +
          "-" +
          this.getMMFromMonthName(this.displayMonthList[5]) +
          "-" +
          this.zeroFill(29);
        const first = new Date(`${eventDate}T00:00:00`);

        events.push({
          name:
            this.calendarData[this.selectedYear][this.displayMonthList[2]][29]
              .Auroral +
            " / " +
            this.calendarData[this.selectedYear][this.displayMonthList[2]][29]
              .Total,
          start: first,
          end: first,
          color: this.getEventColor(
            this.calendarData[this.selectedYear][this.displayMonthList[2]][29]
              .Auroral
          ),
          timed: !allDay,
        });
      }
      this.events = events;
    },
    zeroFill(val: number) {
      return ("00" + val).slice(-2);
    },
    // イベントの色取得
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEventColor(denom: any) {
      if (denom == 0) {
        return "black";
      } else if (denom <= 100) {
        return "green darken-4";
      } else {
        return "green lighten-1";
      }
    },
  },

  watch: {
    // selectedYear のウォッチャ
    // 年が選択されるたびにカレンダーのイベントを生成する
    selectedYear: function () {
      axios
        .get(
          watchPointsDataEndpoints[
            localStorage.getItem("observationPoint") ?? "Tromsø"
          ].calendarBaseURL + this.selectedYear,
          {
            params: {
              timestamp: new Date().getTime(),
            },
          }
        )
        .then((res) => {
          this.calendarData = res.data;
          // カレンダーの表示
          this.setMonthSelector();
          // イベントの作成
          this.setEvent();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
