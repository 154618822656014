

























import Vue from "vue";

import LicenseJson from "../../assets/license.json";

export default Vue.extend({
  data() {
    return {
      licenses: LicenseJson,
    };
  },
});
