

































import axios from "axios";
import Vue from "vue";
import { JifsData } from "../Models/JifsData";
import { burstObservationListConst } from "@/GlobalConst/GlobalConst.vue";
import BurstDialog from "@/components/Dialog/BurstDialog.vue";
export default Vue.extend({
  components: { BurstDialog },
  data() {
    return {
      JifsData: new Array<JifsData>(),
      dialog: false,
    };
  },
  mounted: function () {
    axios
      .get(burstObservationListConst.Tromsø.gifsJsonUrl)
      .then((res) => {
        this.JifsData = res.data;
      })
      .catch((err) => {
        console.debug(err);
        return false;
      });
  },
});
