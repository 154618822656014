
import Vue from "vue";
export default Vue.extend({});

export class globalConst {}

export const monthList = [
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
  "April",
];

export const monthDaysList = [30, 31, 30, 31, 31, 28, 31, 30];

export const monthNameList = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const reverseMonthNameList = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const watchPointsDataEndpoints = {
  Tromsø: {
    Title: "Tromsø AI: Automated Auroral Detection System in Tromsø, Norway",
    DataJson:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/Data.json",
    Calendar:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar/calendar_",
    darkKeogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo_dark.png?",
    keogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo.png?",
    latestViewBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest.jpg?",
    calendarBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar/calendar_",
  },
  Kiruna: {
    Title: "Tromsø AI: Automated Auroral Detection System in Tromsø, Norway",
    DataJson:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/Data_kiruna.json",
    Calendar:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar/calendar_",
    darkKeogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo_dark_kiruna.png?",
    keogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo_kiruna.png?",
    latestViewBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_kiruna.jpg?",
    calendarBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar_kiruna/calendar_",
  },
  Skibotn: {
    Title: "Tromsø AI: Automated Auroral Detection System in Tromsø, Norway",
    DataJson:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/Data_skibotn.json",
    Calendar:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar_skibotn/calendar_",
    darkKeogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo_dark_skibotn.png?",
    keogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo_skibotn.png?",
    latestViewBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_skibotn.jpg?",
    calendarBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar_skibotn/calendar_",
  },
  ALL: {
    Title: "Tromsø AI: Automated Auroral Detection System in Tromsø, Norway",
    DataJson:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/Data_all.json",
    Calendar:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar/calendar_",
    darkKeogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo_dark.png?",
    keogramBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_keo.png?",
    latestViewBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/latest_all.jpg?",
    calendarBaseURL:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/calendar/calendar_",
  },
};

export const watchPoints = ["Tromsø", "Kiruna", "Skibotn", "ALL"];

export const burstObservationListConst = {
  Tromsø: {
    gifsJsonUrl:
      "https://tromsoe-ai.cei.uec.ac.jp/~nanjo/public/aurora_alert/gifs.json",
  },
};

export const archiveStartYear = {
  Tromsø: 2011,
  Kiruna: 2020,
  Skibotn: 2022,
  ALL: 2011,
};
