








import Vue from "vue";
import { watchPoints } from "./GlobalConst/GlobalConst.vue";

export default Vue.extend({
  name: "App",

  data: () => ({
    watchPoints: watchPoints,
  }),
  created: async function () {
    let observationPoint = localStorage.getItem("observationPoint");
    if (
      observationPoint == null ||
      observationPoint == undefined ||
      !this.watchPoints.includes(observationPoint)
    ) {
      localStorage.setItem("observationPoint", "Tromsø");
    }
  },
});
