














































import Vue from "vue";
import axios from "axios";
import Push from "push.js";

import HomeHeader from "../components/HomeHeader/HomeHeader.vue";
import NotifiedClasses from "../components/CardsFeatured/CardNotifiedClasses.vue";
import LatestStatusTable from "../components/CardsFeatured/CardLatestStatusTable.vue";
import Keogram from "../components/CardsFeatured/CardKeogram.vue";
import LatestView from "../components/CardsFeatured/CardLatestView.vue";
import HomeFooter from "../components/HomeFooter/HomeFooter.vue";
import InformationDialog from "../components/InformationDialog/InformationDialog.vue";

import { watchPointsDataEndpoints } from "../GlobalConst/GlobalConst.vue";

type typeDataJson = {
  Time: string;
  Aurora: {
    Arc: number;
    Discrete: number;
    Diffuse: number;
    "Aurora but cloudy": number;
    "Aurora but bright": number;
    Clear: number;
    Cloudy: number;
    "Dusk/Dawn": number;
  };
  Img: {
    Latest: number;
    Keo: number;
  };
};

export default Vue.extend({
  name: "TromsoeAI",

  components: {
    HomeHeader,
    NotifiedClasses,
    LatestStatusTable,
    Keogram,
    LatestView,
    HomeFooter,
    InformationDialog,
  },
  data() {
    let dataJson: typeDataJson = {
      Time: "",
      Aurora: {
        Arc: 0,
        Discrete: 0,
        Diffuse: 0,
        "Aurora but cloudy": 0,
        "Aurora but bright": 0,
        Clear: 0,
        Cloudy: 0,
        "Dusk/Dawn": 0,
      },
      Img: {
        Latest: 0,
        Keo: 0,
      },
    };
    return {
      dataJson,
      timerId: 0,
    };
  },

  created: async function () {
    await this.getDataJson();
    await this.setReloadDataJsonInterval();
  },
  mounted() {
    // 通知の許可を依頼する
    if (!Push.Permission.has()) {
      Push.Permission.request();
    }
  },
  destroyed() {
    // Data.jsonの取得タイマーを解除する
    clearInterval(this.timerId);
  },
  computed: {},
  methods: {
    getDataJson: async function () {
      var lsWatchPoint = localStorage.getItem("observationPoint") ?? "Tromsø";
      // Data.jsonを取得する
      await axios
        .get(watchPointsDataEndpoints[lsWatchPoint].DataJson, {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((res) => {
          this.dataJson = res.data;
          if (lsWatchPoint == "ALL") {
            // Tromsø の時間と同一なせいで、timeの変更検知が効かず、画像が更新されないので+allする
            this.dataJson.Time = this.dataJson.Time + "+all";
          }
          return true;
        })
        .catch((err) => {
          console.debug(err);
          return false;
        });
      return false;
    },
    setReloadDataJsonInterval: async function () {
      // Data.jsonの取得タイミングを設定する
      this.timerId = setInterval(this.getDataJson, 5000);
    },
  },
});
